import { useTranslation } from 'react-i18next';
import styles from './EndpointCard.module.scss';
import { EndpointDto } from 'api/endpoints/models/EndpointDto';
import colors from 'styles/export/colors.module.scss';

type Props = {
    endpoint: EndpointDto;
};

const EndpointCard: React.FC<Props> = ({ endpoint }: Props) => {
    const { t } = useTranslation();

    return (
        <>          
            <div className={styles.titleContainer}>
                <div className={styles.title}>{endpoint.name}</div>
            </div>   
            <div className={styles.statusContainer}>
                <span>{t('servers.list.status')}</span>
                <a data-tip data-for={`status-${endpoint.id}`}>
                    <div className={styles.status} style={{ backgroundColor: endpoint.isAlive ? colors.success : colors.danger }} />
                </a>
            </div>
                     
        </>
    );
};

export default EndpointCard;
