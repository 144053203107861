import { useTranslation } from 'react-i18next';
import styles from './FileCard.module.scss';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { ServerProjectFilesDto } from 'api/projects/models/ProjectDto';
import DangerIcon from 'assets/svg/danger.svg';
import ReactTooltip from 'react-tooltip';
import { DATE_TIME_FORMAT_DEFAUT } from 'Config';

type Props = {
    file: ServerProjectFilesDto;
};

const FileCard: React.FC<Props> = ({ file }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={`${styles.titleContainer} ${file.hasAlert ? styles.marginRight : ''}`}>
                <div className={styles.title}>{file.fileName}</div>
            </div>
            <div className={`${styles.sizeContainer} ${file.hasAlert ? styles.marginRight : ''}`}>
                <span>{t('projects.details.size')}:</span>
                <div className={styles.size} >
                    {file.formattedFileSize}
                </div>
            </div>
            <div className={styles.fill} />
            <div className={`${styles.dateContainer} ${file.hasAlert ? styles.marginRight : ''}`}>
                <span>{t('projects.details.file_date')}:</span>
                <DateFormat className={styles.date} value={file.fileDate ?? ''} format={DATE_TIME_FORMAT_DEFAUT} />
            </div>
            {file.hasAlert &&
                <a data-tip data-for={`alert-${file.fileName}`}>
                    <div className={styles.alertContainer}>
                        <img src={DangerIcon} className={styles.alert} alt="" />
                        <ReactTooltip id={`alert-${file.fileName}`}>
                            <span>{t('common.errors.error_file_min_size')}</span>
                        </ReactTooltip>
                    </div>
                </a>
            }
        </>
    );
};

export default FileCard;
