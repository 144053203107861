import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { POLICIES } from 'Config';
import { Reducers } from 'store/types';
import Sidebar, { SidebarItem } from '../sidebar/Sidebar';
import styles from './AuthenticatedLayout.module.scss';
import UsersService from 'api/users/UsersService';
import { AlertsTotalsDto } from 'api/alerts/models/AlertsTotalsDto';
import { updateTotals } from 'store/alerts/action';

type Props = {
    children: React.ReactNode;
}

type SidebarItemWithValidation = {
    policies: (keyof typeof POLICIES)[];
    type?: 'OR' | 'AND';
} & SidebarItem;

const AuthenticatedLayout: React.FunctionComponent<Props> = ({ children }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAlertsPolicy = UsersService.hasPolicies(loggedUser?.policies || [], ['SETTINGUP_ENDPOINTS_WRITE']);
    const alertsTotals = useSelector<Reducers, AlertsTotalsDto>(state => state.alerts.totals)
    
    const validateItem = useCallback((item: SidebarItemWithValidation) => {
        const type = item.type || 'AND';
        const policies = item.policies || [];
        const userPolicies = loggedUser?.policies || [];

        return UsersService.hasPolicies(userPolicies, policies, type);
    }, [loggedUser]);

    useEffect(() => {
        if (hasAlertsPolicy)
        dispatch(updateTotals());
    }, [])
    
    const sidebarItems: SidebarItemWithValidation[] = useMemo(() => {
        const items: SidebarItemWithValidation[] = [
            {
                text: t('menu.servers'),
                url: '/servers',
                policies: [
                    'SETTINGUP_SERVERS_READ', 'SETTINGUP_SERVERS_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.projects'),
                url: '/projects',
                policies: [
                    'SETTINGUP_PROJECTS_READ', 'SETTINGUP_PROJECTS_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.folders'),
                url: '/folders',
                policies: [
                    'SETTINGUP_FOLDERS_READ', 'SETTINGUP_FOLDERS_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.alerts'),
                url: '/alerts',
                policies: [
                    'SETTINGUP_ALERTS_READ', 'SETTINGUP_ALERTS_WRITE'
                ],
                type: 'OR',                
                badgeValue: alertsTotals.totalNotRead || undefined,
            },
            {
                text: t('menu.security'),
                url: '/security',
                policies: [
                    'SETTINGUP_USERS_READ', 'SETTINGUP_USERS_WRITE',
                    'SETTINGUP_ROLES_READ', 'SETTINGUP_ROLES_WRITE',
                    'SETTINGUP_RULES_READ', 'SETTINGUP_RULES_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.endpoints'),
                url: '/endpoints',
                policies: [
                    'SETTINGUP_ENDPOINTS_READ', 'SETTINGUP_ENDPOINTS_WRITE'                    
                ],
                type: 'OR',
            },
        ];

        return items.filter(item => validateItem(item));
    }, [loggedUser, validateItem, alertsTotals]);

    return (
        <div className={styles.container} >
            <div className={styles.sidebarContainer}>
                <Sidebar items={sidebarItems} />
            </div>
            <div className={styles.contentContainer} id="scrollHere">
                {children}
            </div>
        </div>
    );
}

export default AuthenticatedLayout;
