import i18n, { InitOptions, LanguageDetectorAsyncModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import pt from '../../assets/translations/pt.json';
import { DEFAULT_LANGUAGE, STORAGE, DEFAULTNS } from '../../Config';
import Storage from './Storage';

const languageDetector: LanguageDetectorAsyncModule = {
    type: 'languageDetector',
    async: true,
    detect: (callback: (lng: string) => void) => {
        const language = Storage.get(STORAGE.CURRENT_LOCALE);
        return callback(language || DEFAULT_LANGUAGE);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    init: () => { },
    cacheUserLanguage: (language: string) => {
        Storage.set(STORAGE.CURRENT_LOCALE, language);
    }
};

const config: InitOptions = {
    fallbackLng: DEFAULT_LANGUAGE,
    debug: false,
    initImmediate: false,
    load: 'languageOnly',
    ns: [DEFAULTNS],
    defaultNS: DEFAULTNS,
    interpolation: {
        escapeValue: false
    },
    react: {
        wait: true,
        useSuspense: false
    },
    resources: {
        fr: {
            [DEFAULTNS]: pt
        }
    }
};

i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init(config);

export default i18n;
