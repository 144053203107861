import Http from '../../common/services/Http';
import { Paged } from 'api/common/types/Page';
import { EndpointSearchCriteria } from './models/EndpointSearchCriteria';
import { EndpointDto, EndpointListDto } from './models/EndpointDto';
import { EndpointViewModel } from './models/EndpointViewModel';

class EndpointsService {

    public getList(criteria: EndpointSearchCriteria) {
        return Http.get<Paged<EndpointListDto>>('endpoints', criteria);
    }

    public get(id: string) {
        return Http.get<EndpointDto>(`endpoints/${id}`);
    }


    public create(model: EndpointDto) {
        const formatedModel: EndpointViewModel = {
            id: model.id,
            name: model.name,
            url: model.url
        };

        return Http.post<string>('endpoints', formatedModel);
    }

    public update(model: EndpointDto) {
        const formatedModel: EndpointViewModel = {
            id: model.id,
            name: model.name,
            url: model.url
        };

        return Http.put('endpoints', formatedModel);
    }

    public remove(id: string) {
        return Http.put('endpoints/deactivate', { id: id });
    }
}

export default new EndpointsService();