/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import NumberFormat from 'react-number-format';
import { NUMBERS_MAX_LENGTH, NUMBERS_MAX_VALUE } from '../../../Config';
import styles from './InputGroup.module.scss';

export type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    text?: string;
    icon?: any;
    nameText?: string;
    noBorder?: boolean;
    format?: 'default' | 'money' | 'percentage' | 'number' | 'integer';
    containerClassName?: string;
    onValueChange?: (value: string | number) => void;
    hideMessageLength?: boolean;
    placeholder?: string;
    clickInText?: boolean;
    onClickInText?: () => void;
    isClearable?: boolean;

};

const InputGroup = React.forwardRef<HTMLInputElement, Props>(({ text, icon, noBorder, nameText, format = 'default', onValueChange, hideMessageLength, placeholder, containerClassName, clickInText, onClickInText, isClearable = false, ...props }, ref) => {

    const { t } = useTranslation();
    const [textLength, setTextLength] = useState<number>(0);

    const withValueCap = (inputObj: any) => {
        const { value } = inputObj;
        if (value <= NUMBERS_MAX_VALUE) return inputObj;
    };

    return (
        <div>
            <div className={`${styles.inputGroup} ${containerClassName || ''} ${styles.inputWithButton} `}>
                {nameText && <span className={styles.nameText}>{nameText}</span>}

                {format === 'default' &&
                    <input
                        ref={ref}
                        type={props.type || 'text'}
                        placeholder={placeholder}
                        {...props}
                        onChange={e => {
                            setTextLength(e.currentTarget.value.length);
                            if (props.onChange) {
                                props.onChange(e);
                            }
                            if (onValueChange) {
                                onValueChange(e.currentTarget.value);
                            }
                        }}
                        className={`${styles.input} ${props.className || ''} ${props.disabled ? styles.disabled : ''} `}
                    />

                }

                {(format === 'money' || format === 'percentage' || format === 'number' || format === 'integer') && <NumberFormat
                    getInputRef={ref}
                    displayType="input"
                    thousandSeparator={format === 'money' || format === 'percentage' || format === 'number' ? ' ' : ''}
                    value={props.value as number}
                    type={'text'}
                    decimalScale={format === 'money' || format === 'percentage' || format === 'number' ? 2 : 0}
                    placeholder={placeholder}
                    fixedDecimalScale={format === 'money' || format === 'percentage' || format === 'number' ? true : false}
                    decimalSeparator={(format === 'money' || format === 'percentage' || format === 'number') ? ',' : undefined}
                    onValueChange={e => {
                        if (onValueChange) {
                            onValueChange(e.floatValue ?? (props.type === 'number' || format === 'integer' ? 0 : ''));
                        }
                    }}
                    onChange={e => {
                        setTextLength(e.currentTarget.value.length);
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                    className={`${styles.input} ${styles.inputMoney} ${props.className || ''} ${props.disabled ? styles.disabled : ''} `}
                    disabled={props.disabled}
                    isAllowed={withValueCap}
                />}

                {isClearable && (textLength > 0 || props.value) &&
                    !props.disabled && <FaTimes className={`${styles.clearButton}`} onClick={() => {
                        if (onValueChange) {
                            onValueChange('');
                        }
                    }} />
                }
                {(text || icon) && <div className={` ${styles.inputGroupAppend} ${clickInText ? styles.cursorPointer : ''} `} onClick={() => clickInText ? onClickInText && onClickInText() : null}>
                    <span className={` ${styles.inputGroupText} `}>{icon ? icon : text}</span>
                </div>}

            </div>
            {!hideMessageLength && !(format === 'money' || format === 'percentage') && textLength === props.maxLength && <div className={styles.maxLength}>{t('common.field_max_length', { value: textLength })}</div>}
            {!hideMessageLength && (format === 'money' || format === 'percentage') && textLength === NUMBERS_MAX_LENGTH && <div className={styles.maxLength}>{t('common.field_numbers_max_length', { value: NUMBERS_MAX_LENGTH - 3 })}</div>}
        </div>
    );
});

export default InputGroup;
