/* eslint-disable no-shadow */
import Utils from 'common/services/Utils';
import { RegisterOptions } from 'react-hook-form';

/**
 * Languages
 */
export const DEFAULT_LANGUAGE = 'fr';
export const DEFAULTNS = 'translations';

/**
 * API
 */
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Storage
 */
export const STORAGE_KEY = 'cQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s';
export const STORAGE = {
    CURRENT_LOCALE: 'CURRENT_LOCALE',
    AUTH_USER_TOKEN: 'AUTH_USER_TOKEN',
    AUTH_USER_PROFILE: 'AUTH_USER_PROFILE',
};

/**
 * Logger
 */

export enum LOGGER_MESSAGE_TYPE {
    INFO = 1,
    ERROR,
}

export enum LOGGER_LOG_TYPE {
    REQUEST,
    APP_CRASH,
    VEHICLES,
    USE_CONTRACTS,
    MAINTENANCE_CONTRACTS,
    INSURANCE_CONTRACTS,
    CONTRACTS,
    CHARGES,
    MAINTENANCES,
    ACCIDENTS,
    DRIVERS,
    ALERTS,
    VERIFICATIONS,
}

export const LOGGER_PRINT_LOGS = process.env.REACT_APP_LOGGER_PRINT_LOGS || false;
export const LOGGER_PRINT_LOGS_LEVEL = LOGGER_MESSAGE_TYPE.INFO;

/**
 * DATE FORMAT
 */
export const DATE_FORMAT_DEFAUT = 'DD/MM/YYYY';
export const DATEPICKER_FORMAT_DEFAULT = 'dd/MM/yyyy';
export const DATE_TIME_FORMAT_DEFAUT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_MONTH_YEAR = 'MM/YYYY';

/**
 * NUMBERS
 */
export const NUMBERS_MAX_LENGTH = 14;
export const NUMBERS_MAX_VALUE = 999999999.99;

/**
 * POLICIES
 */
export const POLICIES = {
    SETTINGUP_SERVERS_READ: 'SETTINGUP_SERVERS_READ',
    SETTINGUP_SERVERS_WRITE: 'SETTINGUP_SERVERS_WRITE',
    SETTINGUP_PROJECTS_READ: 'SETTINGUP_PROJECTS_READ',
    SETTINGUP_PROJECTS_WRITE: 'SETTINGUP_PROJECTS_WRITE',
    SETTINGUP_ALERTS_READ: 'SETTINGUP_ALERTS_READ',
    SETTINGUP_ALERTS_WRITE: 'SETTINGUP_ALERTS_WRITE',
    SETTINGUP_FOLDERS_READ: 'SETTINGUP_FOLDERS_READ',
    SETTINGUP_FOLDERS_WRITE: 'SETTINGUP_FOLDERS_WRITE',
    SETTINGUP_USERS_READ: 'SETTINGUP_USERS_READ',
    SETTINGUP_USERS_WRITE: 'SETTINGUP_USERS_WRITE',
    SETTINGUP_ROLES_READ: 'SETTINGUP_ROLES_READ',
    SETTINGUP_ROLES_WRITE: 'SETTINGUP_ROLES_WRITE',
    SETTINGUP_RULES_READ: 'SETTINGUP_RULES_READ',
    SETTINGUP_RULES_WRITE: 'SETTINGUP_RULES_WRITE',
    SETTINGUP_NOTIFICATIONS_READ: 'SETTINGUP_NOTIFICATIONS_READ',
    SETTINGUP_ENDPOINTS_READ : 'SETTINGUP_ENDPOINTS_READ',
    SETTINGUP_ENDPOINTS_WRITE : 'SETTINGUP_ENDPOINTS_WRITE'
};

/**
 * POLICIES
 */
export const ROLES = {
    PLATFORM_ADMINISTRATOR: 'PLATFORM_ADMINISTRATOR',
    ADMINISTRATOR: 'ADMINISTRATOR',
    CONTRIBUTOR: 'CONTRIBUTOR'
};

/**
 * Pagination
 */
export const DEFAULT_PAGINATION_ITEMS_PER_PAGE = 24;
export const DEFAULT_PAGINATION_ITEMS_PER_PAGE_LARGE = 16;
export const MAX_PAGINATION_ITEMS_PER_PAGE = 999999;

/**
 * MIMETYPES
 */
export const MIMETYPES_IMAGE = 'image/png, image/gif, image/jpeg';
export const MIMETYPES_ALL_IMAGE = 'image/*';

export const isTablet = (width: number) => {
    return (width <= 1200);
}

export const removeAccents = (str: string) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const blobToBase64 = (blobFile: Blob): Promise<string> => {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve((reader.result) as string);
        };
        reader.readAsDataURL(blobFile);
    });
}

export const urlToBase64 = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            blobToBase64(xhr.response).then(resolve).catch(reject);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.withCredentials = true;
        xhr.send();
    });
}

export const DEFAULT_INPUT_RULES: RegisterOptions = { minLength: 1, maxLength: 255 }
export const DEFAULT_INPUT_RULES_WITH_REQUIRED: RegisterOptions = { required: true, validate: value => !Utils.isStringNullOrEmpty(value), ...DEFAULT_INPUT_RULES }
export const DEFAULT_EMAIL_RULES: RegisterOptions = { ...DEFAULT_INPUT_RULES, pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/, message: 'common.errors.email_not_valid' } }
export const DEFAULT_EMAIL_RULES_WITH_REQUIRED: RegisterOptions = { required: true, validate: value => !Utils.isStringNullOrEmpty(value), ...DEFAULT_EMAIL_RULES }

export type Types = 'edit' | 'details' | 'new';