import Http from '../../common/services/Http';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { Paged } from 'api/common/types/Page';
import { FolderSearchCriteria } from './models/FolderSearchCriteria';
import { FolderDto, FolderListDto } from './models/FolderDto';
import { FolderViewModel } from './models/FolderViewModel';

class FoldersService {
    public getList(criteria: FolderSearchCriteria) {
        return Http.get<Paged<FolderListDto>>('folders', criteria);
    }

    public get(id: string) {
        return Http.get<FolderDto>(`folders/${id}`);
    }

    public catalog() {
        return Http.get<SelectValueLabel[]>('folders/catalog');
    }

    public create(model: FolderDto) {
        const formatedModel: FolderViewModel = {
            id: model.id,
            name: model.name,
            serverList: (model.serverFolder ?? []).map(x => ({
                id: x.id,
                serverId: x.serverId,              
                lastSync: x.lastSync,
                frequency: x.frequency
            }))
        };

        return Http.post<string>('folders', formatedModel);
    }

    public update(model: FolderDto) {
        const formatedModel: FolderViewModel = {
            id: model.id,
            name: model.name,
            serverList: (model.serverFolder ?? []).map(x => ({
                id: x.id,
                serverId: x.serverId,
                lastSync: x.lastSync,
                frequency: x.frequency
            }))
        };

        return Http.put('folders', formatedModel);
    }

    public remove(id: string) {
        return Http.put('folders/deactivate', { id: id });
    }
}

export default new FoldersService();