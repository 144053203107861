import { Action, AlertsState, SET_TOTALS } from './type';

const initialState: AlertsState = {
    totals: { totalNotRead: 0, totalRead: 0 },
};

function rootReducer(state: AlertsState = initialState, action: Action): AlertsState {
    switch (action.type) {

        case SET_TOTALS:
            return {
                ...state,
                totals: { ...action.totals },
            };

        default:
            return state;
    }
}

export default rootReducer;