import { ProjectInfoDto } from 'api/servers/models/ServerDto';
import Card from 'common/components/card/Card';
import React, { memo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import styles from './ProjectTab.module.scss';

type Props = {
    projects: ProjectInfoDto[];
}

const ProjectTab: React.FC<Props> = ({ projects }: Props) => {
    const { t } = useTranslation();
    return (
        <Row>
            {projects.length > 0
                ? projects.map((x: ProjectInfoDto, index: number) => <Card key={index} projectInfo={x} index={index} large />)
                : <Col xs={12} className={styles.noProject}>
                    <span>{t('servers.details.no_project_association')}</span>
                </Col>}
        </Row>
    );
}

export default memo(ProjectTab);