/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AccessDeniedScreen.module.scss';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import accessDenied from 'assets/img/access_denied.png';
import Button from 'common/components/button/Button';
import { useHistory } from 'react-router';
import Loading from 'common/services/Loading';
import { useLocation } from 'react-router-dom';
import { API_BASE_URL } from 'Config';


const AccessDeniedScreen: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { state } = location;
    const { from: { pathname } } = (state as any);
    const url = API_BASE_URL + pathname;

    useEffect(() => {
        Loading.hide();
    }, []);

    return (
        <ScreenTitle title={t('access_denied.title')}>
            <div className={styles.container}>
                <div className={styles.imageContainer}>
                    <img src={accessDenied} className={styles.image} />
                </div>
                <div className={styles.titleContainer}>
                    <h1>{t('access_denied.title')}</h1>
                </div>
                <div className={styles.descriptionContainer}>
                    <span className={styles.description} dangerouslySetInnerHTML={{ __html: t('access_denied.description_1', { url: url }) }} />
                    <span className={styles.description} dangerouslySetInnerHTML={{ __html: t('access_denied.description_2') }} />
                </div>
                <div className={styles.returnToHome}>
                    <Button
                        type='button'
                        text={t('common.back')}
                        onClick={() => history.push(`/`)} />
                </div>
            </div>
        </ScreenTitle>
    );
};

export default AccessDeniedScreen;
