/* eslint-disable unused-imports/no-unused-vars */
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { UpdateUserDto } from 'api/users/models/UpdateUserDto';
import Loading from 'common/services/Loading';
import UsersService from 'api/users/UsersService';
import Logger from 'common/services/Logger';
import { useForm } from 'react-hook-form';
import { DEFAULT_EMAIL_RULES_WITH_REQUIRED, DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import { useEffect, useState } from 'react';
import CustomFile from 'common/models/CustomFile';
import { Reducers } from 'store/types';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { updateRefreshToken } from 'store/authentication/action';
import { useHistory, useParams } from 'react-router-dom';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import Utils from 'common/services/Utils';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from 'common/components/formItem/FormItem';
import Label from 'common/components/label/Label';
import Input from 'common/components/input/Input';
import InputError from 'common/components/inputError/InputError';
import styles from './AccountScreen.module.scss';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import Button from 'common/components/button/Button';

const AccountScreen = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { type } = useParams<{ type: string }>();
    const history = useHistory();

    const [user, setUser] = useState<UpdateUserDto>();
    const form = useForm<UpdateUserDto>({ shouldUnregister: false, defaultValues: { ...user } });
    const { register, handleSubmit, formState: { errors }, setValue, reset } = form;
    const [mediaFile, setMediaFile] = useState<CustomFile | null>(null);
    const [removeMedia, setRemoveMedia] = useState(false);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const dispatch = useDispatch();

    const getUser = async () => {
        Loading.show();

        try {
            const user = await UsersService.getLoggedUser();
            setUser(user);
            reset(user);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get user info`,
                error
            );
            Loading.hide();
        }
    }

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(form.watch('newPassword') ?? '')) {
            form.clearErrors();
        }
    }, [form.watch('newPassword')]);

    const onSubmit = async (formData: UpdateUserDto) => {
        if (user) {
            try {
                if (formData.newPassword && formData.newPassword !== formData.confirmPassword) {
                    addToast(t('account.passwords_doesnt_match'), { appearance: 'warning' });
                    return;
                }

                Loading.show();

                user.realName = formData.realName;
                user.email = formData.email;
                user.newPassword = formData.newPassword;
                user.removeMedia = removeMedia;

                await UsersService.updateAccount(user, mediaFile);

                user.newPassword = undefined;
                setValue('newPassword', undefined);
                setValue('confirmPassword', undefined);

                dispatch(updateRefreshToken());

                Loading.hide();

                addToast(t('common.messages.record_save_success'), {
                    appearance: 'success',
                });

                history.push(`/account/details`);
            } catch (error) {
                addToast(t('common.messages.record_save_error'), {
                    appearance: 'error',
                });

                Logger.error(
                    LOGGER_LOG_TYPE.REQUEST,
                    `Couldn't create or update user`,
                    error
                );
                Loading.hide();
            }
        }
    };

    const onAddAvatar = (files: CustomFile[]) => {
        if (files.length > 0) {
            setMediaFile(files[0]);
        }
    }

    const onRemoveAvatar = () => {
        setRemoveMedia(true);
        if (user) {
            setUser({
                ...user,
                media: null,
            });
        }
    }

    const onError = async () => {
        addToast(t('common.errors.required_fields_empty'), { appearance: 'warning' });
    }

    return (
        <ScreenTitle title={t('account.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('account.title')}></ScreenHeader>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormItem>
                                <Label className={styles.label}>
                                    {t('users.name' as any)}*
                                </Label>
                                <Input
                                    placeholder={t('users.name')}
                                    defaultValue={user?.realName}
                                    {...register('realName', { ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                    disabled={type == 'details'}
                                />
                                <InputError error={errors.realName} />
                            </FormItem>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormItem>
                                <Label className={styles.label}>
                                    {t('users.email' as any)}*
                                </Label>
                                <Input
                                    placeholder={t('users.email')}
                                    defaultValue={user?.email}
                                    {...register('email', { ...DEFAULT_EMAIL_RULES_WITH_REQUIRED })}
                                    disabled={true}
                                />
                                <InputError error={errors.email} />
                            </FormItem>
                        </Col>
                        {(type != 'details' || (type == 'details' && user?.media)) && <Col xs={12} md={6}>
                            <div className={styles.avatarChooser}>
                                <MediaSelector
                                    isDetails={type == 'details'}
                                    isMulti={false}
                                    onAddMedias={onAddAvatar}
                                    onRemoveMedia={onRemoveAvatar}
                                    initialMedias={user?.media ? [user?.media] : []}
                                />
                            </div>
                        </Col>}
                    </Row>

                    <div className={styles.buttonContainer}>
                        {type == 'details' &&
                            <Button
                                type='button'
                                text={t('common.edit')}
                                onClick={() => history.push(`/account/edit`)} />
                        }
                        {type != 'details' && <>
                            <Button preset={'secondary'}
                                type='button'
                                text={t('common.cancel')}
                                onClick={() => history.push(`/account/details`)} />
                            <Button
                                type='submit'
                                text={t('common.save')} />
                        </>}
                    </div>
                </form>
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default AccountScreen;