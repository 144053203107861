import { Dispatch } from 'redux';
import Storage from '../../common/services/Storage';
import { STORAGE } from '../../Config';
import { UserProfile } from '../../api/account/models/UserProfile';
import { SET_AUTHENTICATION, SET_PROFILE, UPDATE_REFRESH_TOKEN } from './type';
import { UserToken } from 'api/account/models/UserToken';

export const initAuthentication = (cb: (rt: boolean) => void) => async (dispatch: Dispatch) => {
	try {

		const profile = Storage.getObject(STORAGE.AUTH_USER_PROFILE);
		const token = Storage.getObject(STORAGE.AUTH_USER_TOKEN);
		if (!profile || !token) {
			cb(false);
			return;
		}

		dispatch(setAuthentication(profile, token));

		cb(true);
	} catch (ex) {
		dispatch(logout() as any);
		cb(false);
	}
}

export const authenticateUser = (profile: UserProfile, token: UserToken) => async (dispatch: Dispatch) => {
	Storage.setObject(STORAGE.AUTH_USER_PROFILE, profile);
	Storage.setObject(STORAGE.AUTH_USER_TOKEN, token);

	dispatch(setAuthentication(profile, token));
}

export const logout = () => async (dispatch: Dispatch) => {
	Storage.remove(STORAGE.AUTH_USER_TOKEN);
	Storage.remove(STORAGE.AUTH_USER_PROFILE);

	dispatch(setAuthentication(null, null));
}

export const setAuthentication = (profile: UserProfile | null, token: UserToken | null) => ({
	type: SET_AUTHENTICATION,
	profile,
	token,
});

export const setProfile = (profile: UserProfile | null) => ({
	type: SET_PROFILE,
	profile,
});

export const updateRefreshToken = () => ({
	type: UPDATE_REFRESH_TOKEN,
});
