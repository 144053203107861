import Http from '../../common/services/Http';
import { UserProfile } from './models/UserProfile';
import { LoginViewModel } from './models/LoginViewModel';
import { PasswordResetTokenViewModel } from './models/PasswordResetTokenViewModel';
import { PasswordResetViewModel } from './models/PasswordResetViewModel';
import { UserLogin } from './models/UserLogin';

class AccountService {

    public login(model: LoginViewModel) {
        return Http.post<UserLogin>('account/token', model);
    }

    public generateResetPasswordCode(model: PasswordResetTokenViewModel) {
        return Http.post('account/generate-reset-password-code', model);
    }

    public resetPassword(model: PasswordResetViewModel) {
        return Http.post<string>('account/reset-password', model);
    }

    public profile() {
        return Http.get<UserProfile>('account/profile');
    }
}

export default new AccountService();
