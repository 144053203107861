import React from 'react';
import styles from './Tabs.module.scss';
import WarningIcon from 'assets/svg/warning.svg';

export interface TabItem {
    id: string;
    title: string;
    content?: React.ReactElement;
    badge?: number;
    error?: boolean;
}

type Props = {
    items: TabItem[];
    activeTabId: string;
    onChange: (tabId: string) => void;
}

const Tabs = ({ items, activeTabId, onChange }: Props) => {
    const activeTab = items.find(x => x.id === activeTabId)!;
    return (
        <div className={styles.container}>
            <div className={styles.list}>
                {items.map(item => (
                    <div
                        key={`tab-${item.id}`}
                        onClick={() => { onChange(item.id); }}
                        className={`${styles.listItem} ${activeTabId == item.id && styles.listItemActive}`}
                    >
                        <div style={{ position: 'relative' }}>
                            <span style={{ marginRight: !!item.error ? '1.5rem' : 'unset' }}>{item.title}</span>
                            {!!item.badge &&
                                <span className={styles.floatingBadge}>
                                    {item.badge > 99 ? <small>99+</small> : item.badge}
                                </span>}
                            {!!item.error &&
                                <div className={styles.warningContainer}>
                                    <img src={WarningIcon} className={styles.icon} />
                                </div>}
                        </div>
                    </div>
                ))}
            </div>
            {Boolean(activeTab) && <div key={`tab-content-${activeTab.id}`} className={styles.content}>
                {activeTab!.content}
            </div>}
        </div>
    );
};

export default Tabs;