import Select, { Props as SelectProps } from './Select';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';
import { ActionMeta } from 'react-select';

type Props = {
    form: UseFormReturn<any>;
    name: string;
    options: SelectValueLabel[];
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    isClearable?: boolean;
    onChangeSelect?: (data: any) => void;
} & SelectProps;

const SelectController = ({ form, name, options, rules, isClearable, onChangeSelect, ...props }: Props) => {
    return (
        <Controller
            render={({ field }) => {
                return (
                    <Select
                        {...props}
                        { ...field}
                        options={options}
                        value={props.isMulti 
                            ? field.value ? options.filter(x => field.value.find((y: any) => y === x.value)) : []
                            : options.find(x => String(x.value || null).toString() === String(field.value || null).toString()) || null}
                        isClearable={isClearable}
                        onChange={(newValue: unknown, actionMeta: ActionMeta<unknown>) => {

                            if (props.isMulti) {
                                field.onChange((newValue as any)?.map((x: any) => x.value));
                            } else {
                                field.onChange((newValue as any)?.value);
                            }

                            if (props.onChange) {
                                props.onChange((newValue as any), actionMeta);
                            }
                            onChangeSelect && onChangeSelect((newValue as any));
                        }}
                    />
                );
            }}
            control={form.control}
            name={name}
            defaultValue={form.getValues(name) || null}
            rules={rules}
        />
    );
};

export default SelectController;
