import { AlertInfoDto } from 'api/servers/models/ServerDto';
import Card from 'common/components/card/Card';
import React, { memo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import styles from './AlertTab.module.scss';

type Props = {
    alerts: AlertInfoDto[];
    reload: () => void;
}

const AlertTab: React.FC<Props> = ({ alerts, reload }: Props) => {
    const { t } = useTranslation();

    return (
        <Row>
            {alerts.length > 0
                ? alerts.map((x: AlertInfoDto, index: number) => <Card key={index} alert={x} index={index} large action={reload} />)
                : <Col xs={12} className={styles.noAlert}>
                    <span>{t('servers.details.no_alert')}</span>
                </Col>}
        </Row>
    );
}

export default memo(AlertTab);