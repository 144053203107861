 import Http from '../../common/services/Http';
 import { RuleDto } from './models/RuleDto';

class RuleService {

    public getAll() {
        return Http.get<RuleDto[]>(`rules`);
    }

    public update(model: RuleDto[]) {
        return Http.put('rules', { rules : model });
    }

}

export default new RuleService();