import React, { ReactElement } from 'react';
import styles from './Box.module.scss';

export type Props = {
    title?: string;
    renderOptions?: ReactElement;
    styles?: any;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Box = ({ children, renderOptions, title, ...props }: Props) => (
    <div {...props} className={`${styles.container} ${props.className || ''}`}>
        {(title || renderOptions) && <div className={styles.header}>
            <div className={styles.headerTitle} style={{ flex: renderOptions ? 'unset' : 1 }}>{title}</div>
            {renderOptions && <div className={styles.headerOptions}>{renderOptions}</div>}
        </div>}
        <div style={props.styles ?? props.styles}>
            {children}
        </div>
    </div>
)

export default Box;
