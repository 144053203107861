import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { UserDto } from 'api/users/models/UserDto';
import Loading from 'common/services/Loading';
import UsersService from 'api/users/UsersService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import InputError from 'common/components/inputError/InputError';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { useForm } from 'react-hook-form';
import { DEFAULT_EMAIL_RULES_WITH_REQUIRED, DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import React, { useEffect, useState } from 'react';
import RolesService from 'api/roles/RolesService';
import CheckBoxController from 'common/components/checkBox/CheckBoxController';
import { RoleDto } from 'api/roles/models/RoleDto';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { CheckAvailabilityDto } from 'api/common/models/CheckAvailabilityDto';
import styles from './UsersDetails.module.scss';
import { Col, Row } from 'react-flexbox-grid';
import CustomFile from 'common/models/CustomFile';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import { Reducers } from 'store/types';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { updateRefreshToken } from 'store/authentication/action';
import { useHistory, useParams } from 'react-router-dom';
import Input from 'common/components/input/Input';
type Props = {
};

const UsersDetails: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { id, type } = useParams<{ id: string, type: string }>();

    const [user, setUser] = useState<UserDto>();
    const form = useForm<UserDto>({ shouldUnregister: false, defaultValues: { ...user } });
    const { register, handleSubmit, formState: { errors }, watch, reset } = form;
    const [allRoles, setAllRoles] = useState<RoleDto[]>([]);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<UserDto | null>(null);
    const [mediaFile, setMediaFile] = useState<CustomFile | null>(null);
    const [removeMedia, setRemoveMedia] = useState(false);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasUsersWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_USERS_WRITE']);

    const dispatch = useDispatch();
    const history = useHistory();

    const goToList = () => {
        history.push(`/security/users`);
    };

    const onCancel = () => {
        if (type == 'details') {
            history.push('/security/users');
        } else if (!id) {
            history.goBack();
        } else {
            getUser();
            history.push(`/security/users/details/${id}`);
        }
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await UsersService.remove(itemToRemove);
            onCancelRemove();
            goToList();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete user`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const getUser = async () => {
        Loading.show();

        try {
            const rolesList = await RolesService.getAll();
            let u: UserDto = { id: '', realName: '', email: '', roles: [], temporaryRoles: [] };
            if (!!id) {
                u = await UsersService.getById(id);
            }
            setAllRoles([...rolesList]);

            u.temporaryRoles = rolesList.map(role => {
                const __role = { ...role };
                __role.checked = (u.roles?.includes(__role.name) ?? false);
                return __role;
            });

            u.temporaryRoles.forEach(role => {
                if (role.readOnly || role.system) {
                    role.name = t(('common.roles.' + role.name) as any);
                }
            });

            setUser(u);
            reset(u);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get catalog users types`,
                error
            );
            Loading.hide();
        }
    }

    useEffect(() => {
        getUser();
    }, [type]);

    const showRemoveItemDialog = async (item: UserDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onSubmit = async (formData: UserDto) => {
        try {

            if (!formData.temporaryRoles || formData.temporaryRoles?.find(e => e.checked === true) == null) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            const emailData: CheckAvailabilityDto = { table: 'asp_net_users', column: 'normalized_email', value: formData.email.toLocaleUpperCase(), companyId: true, itemEdit: user?.id };
            const checkAvailabilityEmail = await UsersService.checkAvailability(emailData);
            if (!checkAvailabilityEmail) {
                addToast(t('common.messages.email_already_exists'), { appearance: 'warning' });
                return;
            }

            Loading.show();
            if (user) {
                user.realName = formData.realName;
                user.email = formData.email;
                user.roles = [];
                user.removeMedia = removeMedia;

                if (formData.temporaryRoles) {
                    formData.temporaryRoles.forEach((r, i) => {
                        if (r.checked) {
                            user.roles?.push(allRoles[i].name);
                        }
                    });
                }
            }

            if (user && user.id != '') {
                await UsersService.update(user, mediaFile);
            } else if (user) {
                await UsersService.create(user, mediaFile);
            }

            if (user?.id === loggedUser?.id) {
                dispatch(updateRefreshToken());
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), {
                appearance: 'success',
            });
            onCancel();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't create or update user`,
                error
            );
            Loading.hide();
        }
    };

    const onAddAvatar = (files: CustomFile[]) => {
        if (files.length > 0) {
            setMediaFile(files[0]);
        }
    }

    const onRemoveAvatar = () => {
        setRemoveMedia(true);
        if (user) {
            setUser({
                ...user,
                media: null,
            });
        }
    }

    const onError = async () => {
        addToast(t('common.errors.required_fields_empty'), { appearance: 'warning' });
    }

    return (
        <div>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => goToList()} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('users.title')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
                <Row>
                    <Col xs={12} md={6}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.name' as any)}*
                            </Label>
                            <Input
                                placeholder={t('users.name')}
                                defaultValue={user?.realName}
                                {...register('realName', { ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.realName} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.email' as any)}*
                            </Label>
                            <Input
                                placeholder={t('users.email')}
                                defaultValue={user?.email}
                                {...register('email', { ...DEFAULT_EMAIL_RULES_WITH_REQUIRED })}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors.email} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('roles.title')}*
                            </Label>
                            <div className={styles.roles}>
                                {user?.temporaryRoles.map((role, i) => {
                                    return (<CheckBoxController key={i} form={form as any} name={`temporaryRoles[${i}].checked`} label={role.name} disabled={type == 'details'} />);
                                })}
                            </div>
                            <InputError error={form.formState.isSubmitted && watch('temporaryRoles').filter(x => x.checked).length === 0 ? { type: 'dateRange', message: t('common.errors.error_no_role_selected') } : undefined} />
                        </FormItem>
                    </Col>
                    {(type != 'details' || (type == 'details' && user?.media)) && <Col xs={12} md={6}>
                        <div className={styles.avatarChooser}>
                            <MediaSelector
                                isDetails={type == 'details'}
                                isMulti={false}
                                onAddMedias={onAddAvatar}
                                onRemoveMedia={onRemoveAvatar}
                                initialMedias={user?.media ? [user?.media] : []}
                            />
                        </div>
                    </Col>}
                </Row>

                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onCancel()} />
                    {type == 'details' && hasUsersWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: user?.id } as UserDto)} />
                    }
                    {type == 'details' && hasUsersWritePolicy &&
                        <Button
                            type='button'
                            text={t('common.edit')}
                            onClick={() => history.push(`/security/users/edit/${id}`)} />
                    }
                    {type != 'details' && hasUsersWritePolicy &&
                        <Button
                            type='submit'
                            text={t('common.save')} />
                    }
                </div>
            </form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.realName ?? '' })}></QuestionYesNo>
        </div>
    );
};

export default UsersDetails;
