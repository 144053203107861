import { useTranslation } from 'react-i18next';
import styles from './FolderInfoCard.module.scss';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { FolderInfoDto } from 'api/servers/models/ServerDto';

type Props = {
    folderInfo: FolderInfoDto;
};

const FolderInfoCard: React.FC<Props> = ({ folderInfo }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{folderInfo.name}</div>
            </div>
            <div className={styles.syncContainer}>
                <span>{t('folders.details.last_sync')}:</span>
                {!!folderInfo.lastSync ?
                    <DateFormat className={styles.sync} value={folderInfo.lastSync ?? ''} />
                    : <span className={styles.sync}>{t('folders.details.no_last_sync')}</span>}
            </div>
            <div className={styles.fill} />
            <div className={styles.dateContainer}>
                <span>{t('projects.list.createdDate')}</span>
                <DateFormat className={styles.date} value={folderInfo.createdDate ?? ''} />
            </div>
        </>
    );
};

export default FolderInfoCard;
