import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';
import { EndpointDto } from 'api/endpoints/models/EndpointDto';
import { EndpointSearchCriteria } from 'api/endpoints/models/EndpointSearchCriteria';
import Loading from 'common/services/Loading';
import EndpointsService from 'api/endpoints/EndpointsService';
import Logger from 'common/services/Logger';
import { Paged } from 'api/common/types/Page';
import styles from './EndpointsListScreen.module.scss';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
// import EndpointsFiltersScreen, { EndpointFilters } from 'screens/security/components/endpointsTab/components/endpointsFilters/EndpointsFiltersScreen';
import { useHistory } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import UsersService from 'api/users/UsersService';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { Col, Row } from 'react-flexbox-grid';
import Card from 'common/components/card/Card';

type Props = {
};

const EndpointsList: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [endpointsPage, setEndpointsPage] = useState<Paged<EndpointDto>>();
    const [criteria, setCriteria] = useState<EndpointSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'e.name'
    });

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<EndpointDto | null>(null);
    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasEndpointsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ENDPOINTS_WRITE']);

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            //await EndpointsService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete endpoint`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const getData = async () => {
        try {
            Loading.show();
            const page = await EndpointsService.getList(criteria);
            setEndpointsPage(page);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get endpoints list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };


    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    const navigateTo = (typeUrl?: string, id?: string) => {
        history.push(`/security${typeUrl ? `/endpoints/${typeUrl}${id ? `/${id}` : ''}` : ''}`);
    }

    useEffect(() => {
        getData();
    }, [criteria]);


    const navigateToNew = () => {
        history.push('/endpoints/new');
    }



    return (
        <ScreenTitle title={t('endpoints.list.endpoints')}>
            <ScreenContainer>
                <ScreenHeader title={t('endpoints.list.endpoints')} >
                    <InputSearch onChangeValue={debounced} placeholder={t('common.search')} ></InputSearch>
                    {hasEndpointsWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateToNew()} />}
                </ScreenHeader>

                <div className='list'>                  
                    <Row>
                        {(endpointsPage?.items || []).length > 0 ?
                            (endpointsPage?.items || []).map((x: EndpointDto, index: number) => <Card key={index} endpoint={x} index={index} />)
                            : <Col xs={12}><div className={styles.center}>{t('endpoints.list.no_item')}</div></Col>}
                    </Row>
                    <PaginationWithInfo
                        itemName={t('endpoints.list.endpoints')}
                        currentPage={endpointsPage?.currentPage || 1}
                        pageItems={endpointsPage?.items.length || 0}
                        totalItems={endpointsPage?.totalItems || 0}
                        itemsPerPage={DEFAULT_PAGINATION_ITEMS_PER_PAGE}
                        onChange={page => setCriteria({ ...criteria, page })}
                    />
                    <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.name ?? '' })}></QuestionYesNo>
                </div>
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default EndpointsList;
