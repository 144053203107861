import { ServerProjectDto, ServerProjectFilesDto } from 'api/projects/models/ProjectDto';
import Card from 'common/components/card/Card';
import DateFormat from 'common/components/dateFormat/dateFormat';
import React, { memo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import styles from './ServerProjectTab.module.scss';
import { BsInfoCircle } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';

type Props = {
    serverProject: ServerProjectDto;
    files: ServerProjectFilesDto[];
}

const ServerProjectTab: React.FC<Props> = ({ serverProject, files }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <Row>
                <Col className={styles.marginBottom} md={12}>
                    <div className={styles.container}>
                        <div>
                            {t('projects.details.serverName')}:
                        </div>
                        <div className={styles.title}>{serverProject.serverName}</div>
                    </div>
                </Col>
                <Col className={styles.marginBottom} md={12} lg={6} xl={4}>
                    <div className={styles.container}>
                        <div>
                            {t('projects.details.regexExpression')}:
                        </div>
                        {!!serverProject.fileRegex && <div className={styles.title}>{serverProject.fileRegex}</div>}
                    </div>
                </Col>
                <Col className={styles.marginBottom} md={12} lg={6} xl={4}>
                    <div className={styles.container}>
                        <div>
                            {t('projects.details.periodicity.title')}:
                        </div>
                        {!!serverProject.periodicity && <div className={styles.title}>{t(`projects.details.periodicity.${serverProject.periodicity}` as any)}</div>}
                    </div>
                </Col>
                <Col className={styles.marginBottom} md={12} lg={6} xl={4}>
                    <div className={styles.container}>
                        <div>
                            {t('projects.details.retainer')}:
                        </div>
                        {!!serverProject.retainer && <div className={styles.title}>{serverProject.retainer}</div>}
                    </div>
                </Col>
                <Col className={styles.marginBottom} md={12} lg={6} xl={4}>
                    <div className={styles.container}>
                        <div>
                            {t('projects.details.min_size_small')}:
                        </div>
                        {!!serverProject.formattedMinSize && <div className={styles.title}>{serverProject.formattedMinSize}</div>}
                    </div>
                </Col>
                <Col className={styles.marginBottom} md={12} lg={6} xl={4}>
                    <div className={styles.container}>
                        <a data-tip data-for={'date'}>
                            <div className={styles.dateContainer}>
                                {t('projects.details.start_date')} <BsInfoCircle size={15} style={{ marginLeft: '0.2rem' }} />:
                            </div>
                        </a>
                        <ReactTooltip id={'date'}>
                            <span>{t('projects.details.start_date_tooltip')}</span>
                        </ReactTooltip>
                        <div className={styles.title}>
                            {!!serverProject.startDate && <DateFormat className={styles.date} value={serverProject.startDate} />}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <span className={styles.title}>{t('projects.details.files')}</span>
                </Col>
                {files.length > 0
                    ? files.map((x: ServerProjectFilesDto, index: number) => <Card key={index} file={x} index={index} large noAction />)
                    : <Col xs={12} className={styles.noProject}>
                        <span>{t('projects.details.no_files')}</span>
                    </Col>}
            </Row>
        </>
    );
}

export default memo(ServerProjectTab);