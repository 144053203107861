import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import { LOGGER_LOG_TYPE, Types } from 'Config';
import { Col, Row } from 'react-flexbox-grid';
import Label from 'common/components/label/Label';
import styles from './EndpointScreen.module.scss';
import { useForm } from 'react-hook-form';
import InputError from 'common/components/inputError/InputError';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Input from 'common/components/input/Input';
import Button from 'common/components/button/Button';
import { EndpointDto } from 'api/endpoints/models/EndpointDto';
import EndpointsService from 'api/endpoints/EndpointsService';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';

type Props = {
};

const EndpointScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const { endpointId, type } = useParams<{ endpointId: string, type: Types }>();
    const isNew = type === 'new';
    const history = useHistory();
    const [itemToRemove, setItemToRemove] = useState<EndpointDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVerificationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ENDPOINTS_WRITE']);
    const hasReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ENDPOINTS_READ']);
    
    const form = useForm<EndpointDto>();
    const { register, handleSubmit, formState: { errors }, reset } = form;

    const [endpoint, setEndpoint] = useState<EndpointDto | null>(null);

    const getData = async () => {
        try {
            if (!endpointId) return;
            Loading.show();

            const result = await EndpointsService.get(endpointId);
            setEndpoint(result);
            reset({
                id: result.id,
                name: result.name,
                url: result.url
            });

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }


    const onSubmit = async (formData:EndpointDto) => {
        try {
            Loading.show();
            if (isNew) {
                const id = await EndpointsService.create(formData);
                history.push(`/endpoints/details/${id}`);
            } else {
                await EndpointsService.update(formData);
                history.push(`/endpoints/details/${endpointId}`);
            }

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update servers`, error);
            Loading.hide();
        }
    };


    useEffect(() => {
        if(!hasReadPolicy)
        {
            history.push('/access-denied');
        }
        reset(undefined);      
        getData();
    }, [type, endpointId]);


    const onCancel = () => {
        if (type == 'details') {
            goToList();
        } else if (!endpointId) {
            history.goBack();
        } else {           
            history.push(`/endpoints/details/${endpointId}`);
        }
    };

    const goToList = () => {
        history.push(`/endpoints`);
    };


    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    
    const showRemoveItemDialog = async (item: EndpointDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await EndpointsService.remove(itemToRemove?.id);
            onCancelRemove();
            goToList();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete endpoints`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };



    return (
        <ScreenTitle title={t('endpoints.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('endpoints.title')} />
        <Box>
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className={styles.container}>
                    <Row className={styles.rowSpace}>
                        <Col xs={12}>
                            <Label className={styles.label}>{t('endpoints.details.name')} *</Label>
                            <Input
                                placeholder={t('endpoints.details.name')}
                                defaultValue={endpoint?.name}
                                {...register('name', { required: true })}
                                disabled={type == 'details'}
                            />
                            <InputError error={errors?.name} />
                        </Col>                       
                    </Row>                    
                    <Row className={styles.rowSpace}>
                        <Col xs={12}>
                            <Label className={styles.label}>{t('endpoints.details.url')} *</Label>
                            <Input
                                placeholder={t('endpoints.details.url')}
                                defaultValue={endpoint?.url}
                                {...register('url', { required: true })}
                                 disabled={type == 'details'}
                            />
                            <InputError error={errors?.url} />
                        </Col>                       
                    </Row>                   
                </div> 
                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onCancel()}
                    />
                       {type == 'details' && hasVerificationsWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: endpoint?.id } as EndpointDto)} />
                    }
                    {type == 'details' && hasVerificationsWritePolicy &&
                        <Button
                            type='button'
                            text={t('common.edit')}
                            onClick={() => history.push(`/endpoints/edit/${endpoint?.id}`)} />
                    }
                    {type != 'details' && hasVerificationsWritePolicy &&
                        <Button
                            type='submit'
                            text={t('common.save')} />
                    }
                </div>              
            </form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.name ?? '' })}></QuestionYesNo>
        </Box>
        </ScreenContainer>
        </ScreenTitle>);
    };

export default EndpointScreen;
