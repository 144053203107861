/* eslint-disable no-shadow */
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HomeScreen.module.scss';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';

const HomeScreen: React.FunctionComponent = () => {
    const { t } = useTranslation();
    
    return (
        <ScreenTitle title={t('menu.home')}>
            <div className={styles.container}>
                
            </div>
        </ScreenTitle>
    );
};

export default HomeScreen;
