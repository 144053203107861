import { FolderInfoDto } from 'api/servers/models/ServerDto';
import Card from 'common/components/card/Card';
import React, { memo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import styles from './FolderTab.module.scss';

type Props = {
    folders: FolderInfoDto[];
}

const FolderTab: React.FC<Props> = ({ folders }: Props) => {
    const { t } = useTranslation();
    return (
        <Row>
            {folders.length > 0
                ? folders.map((x: FolderInfoDto, index: number) => <Card key={index} folderInfo={x} index={index} large />)
                : <Col xs={12} className={styles.noProject}>
                    <span>{t('servers.details.no_folder_association')}</span>
                </Col>}
        </Row>
    );
}

export default memo(FolderTab);