/* eslint-disable react/display-name */
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale, ReactDatePickerProps as DateProps } from 'react-datepicker';
import styles from './DateTimePicker.module.scss'
import { fr, pt, enGB  } from 'date-fns/locale';
registerLocale('fr', fr)
registerLocale('pt', pt)
registerLocale('enGB', enGB)

export type Props = {
} & DateProps;

const DateTimePicker = React.forwardRef<DatePicker, Props>(({ ...props  }, ref) => {
  
  return (
      <DatePicker
        {...props}
        ref={ref}
        showYearDropdown
        wrapperClassName={styles.fullWidth}
      />
    );
});

export default DateTimePicker;