/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LibSelect, { Props as SelectProps } from 'react-select';
import colors from './../../../styles/export/colors.module.scss';

export type Props = {
    isDisabled: boolean;
    isSimple?: boolean;
    ignoreMenuPortalTarget?: boolean;
} & SelectProps;

const Select = ({ ignoreMenuPortalTarget, ...props }: Props) => {
    const { t } = useTranslation();
    const ref = useRef(null);

    return (
        <LibSelect
            {...props}
            ref={ref}
            menuPortalTarget={!ignoreMenuPortalTarget ? document.querySelector('body') : null}
            noOptionsMessage={() => t('common.no_options')}
            styles={{
                control: (base, state) => ({
                    ...base,
                    backgroundColor: props.isDisabled ? colors.whisper : colors.white,
                    borderRadius: '20px !important',
                    border: props.isSimple ? '0px' : state.isFocused ? `1px solid ${colors.primaryLight}` : `1px solid ${colors.mercury}`,
                    boxShadow: 'none',
                    ':hover': {
                        borderColor: state.isFocused ? colors.primaryLight : colors.mercury,
                    },

                }),
                placeholder: base => ({
                    ...base,
                    color: colors.frenchGray,
                    opacity: 0.54,
                }),
                valueContainer: base => ({
                    ...base,
                    padding: props.isSimple ? '6px 0.5rem' : '6px 1rem',
                    justifyContent: props.isSimple ? 'flex-end' : 'initial'
                }),
                option: (base, state) => ({
                    ...base,
                    color: state.isSelected ? colors.white : colors.black,
                    backgroundColor: state.isSelected ? colors.primaryDark : colors.white,
                    ':hover': {
                        backgroundColor: colors.lightGrayishBlue,
                        color: colors.black
                    },
                }),
                singleValue: base => ({
                    ...base,
                    color: colors.lightblack,
                }),
                multiValueLabel: base => ({
                    ...base,
                    backgroundColor: colors.solitude,
                    color: colors.black,
                }),
                multiValueRemove: base => ({
                    ...base,
                    backgroundColor: colors.solitude,
                    color: colors.black,
                }),
                indicatorSeparator: base => ({
                    ...base,
                    width: props.isSimple ? '0px' : '1px',
                    backgroundColor: colors.mercury
                }),
            }}
        />
    );
};

export default Select;
