import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';
import { UserDto } from 'api/users/models/UserDto';
import { UserSearchCriteria } from 'api/users/models/UserSearchCriteria';
import Loading from 'common/services/Loading';
import UsersService from 'api/users/UsersService';
import Logger from 'common/services/Logger';
import { Paged } from 'api/common/types/Page';
import Button from 'common/components/button/Button';
import styles from './UsersList.module.scss';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Popover from 'common/components/popover/Popover';
import UsersFiltersScreen, { UserFilters } from 'screens/security/components/usersTab/components/usersFilters/UsersFiltersScreen';
import { useHistory } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { Col, Row } from 'react-flexbox-grid';
import Card from 'common/components/card/Card';

type Props = {
};

const UsersList: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [usersPage, setUsersPage] = useState<Paged<UserDto>>();
    const [criteria, setCriteria] = useState<UserSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'u.real_name'
    });

    const [filters, setFilters] = useState<UserFilters>({});
    const [filtersTotal, setFiltersTotal] = useState(0);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<UserDto | null>(null);
    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasUsersWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_USERS_WRITE']);

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await UsersService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete user`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const getData = async () => {
        try {
            Loading.show();
            const page = await UsersService.getList(criteria);
            setUsersPage(page);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get users list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onChangeFilters = (f: UserFilters) => {
        updateTotalFilters(f);
        setFilters(f);
        setCriteria({ ...criteria, roles: f.roles?.map(r => r.value), page: 1 });
    }

    const updateTotalFilters = (f: UserFilters) => {
        let total = 0;
        if (f.roles && f.roles.length > 0) {
            total++;
        }

        setFiltersTotal(total);
    }

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    const navigateTo = (typeUrl?: string, id?: string) => {
        history.push(`/security${typeUrl ? `/users/${typeUrl}${id ? `/${id}` : ''}` : ''}`);
    }

    useEffect(() => {
        getData();
    }, [criteria]);

    return (
        <div className='list'>
            <div className={styles.tabHeader}>
                <div>
                    <InputSearch onChangeValue={debounced} placeholder={t('common.search')} ></InputSearch>
                </div>

                <div className={styles.rowFlex}>
                    <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        positions={['bottom', 'left']}
                        align={'end'}
                        onClickOutside={() => updateTotalFilters(filters)}
                        content={setIsPopoverOpen => <UsersFiltersScreen
                            filters={filters}
                            onFilter={f => { setIsPopoverOpen(false); onChangeFilters(f) }}
                            onChange={f => updateTotalFilters(f)}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <Button onClick={() => setIsPopoverOpen(!isPopoverOpen)}>{t('common.filters')}
                                {filtersTotal > 0 &&
                                    <div className={styles.counter}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                            </Button>
                        )}
                    </Popover>
                    {hasUsersWritePolicy && <Button
                        preset='success'
                        text={t('common.new')}
                        onClick={() => navigateTo('new')}>
                    </Button>}
                </div>
            </div>
            <Row>
                {(usersPage?.items || []).length > 0 ?
                    (usersPage?.items || []).map((x: UserDto, index: number) => <Card key={index} user={x} index={index} />)
                    : <Col xs={12}><div className={styles.center}>{t('users.no_user_associated')}</div></Col>}
            </Row>
            <PaginationWithInfo
                itemName={t('users.list.users')}
                currentPage={usersPage?.currentPage || 1}
                pageItems={usersPage?.items.length || 0}
                totalItems={usersPage?.totalItems || 0}
                itemsPerPage={DEFAULT_PAGINATION_ITEMS_PER_PAGE}
                onChange={page => setCriteria({ ...criteria, page })}
            />
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.realName ?? '' })}></QuestionYesNo>
        </div>
    );
};

export default UsersList;
