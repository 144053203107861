import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';

import { AlertSearchCriteria } from './models/AlertSearchCriteria';
import { AlertDto } from './models/AlertDto';
import { AlertsTotalsDto } from './models/AlertsTotalsDto';

class AlertsService {
    public getList(criteria:AlertSearchCriteria) {
        return Http.get<Paged<AlertDto>>('alerts', criteria);
    }

    public get(id: string) {
        return Http.get<AlertDto>(`alerts/${id}`);
    }

    public getTotals() {
        return Http.get<AlertsTotalsDto>('alerts/totals');
    }

    public read(alertId: string) {
        return Http.put('alerts/read', { alertId });
    }

    public readAll() {
        return Http.get('alerts/read-all');
    }
}

export default new AlertsService();