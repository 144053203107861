import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListingTable, {
    ListingTableColumn,
} from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';
import { RoleDto } from 'api/roles/models/RoleDto';
import { RoleSearchCriteria } from 'api/roles/models/RoleSearchCriteria';
import Loading from 'common/services/Loading';
import RolesService from 'api/roles/RolesService';
import Logger from 'common/services/Logger';
import { Paged } from 'api/common/types/Page';
import Button from 'common/components/button/Button';
import styles from './RolesList.module.scss';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import DropdownItem from 'common/components/popover/PopoverItem';
import Dropdown from 'common/components/dropdown/Dropdown';
import { FaEllipsisH } from 'react-icons/fa';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { useHistory } from 'react-router';

const RolesList = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [rolesPage, setRolesPage] = useState<Paged<RoleDto>>();
    const [criteria, setCriteria] = useState<RoleSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'r.name'
    });

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasRolesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ROLES_WRITE']);
    const history = useHistory();
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<RoleDto | null>(null);

    const showRemoveItemDialog = async (item: RoleDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onSelectRole = (item: RoleDto | null, details: boolean) => {
        if (!item) {
            history.push(`/security/roles/new`);
            return;
        }
        history.push(`/security/roles/${details ? 'details' : 'edit'}/${item.id}`);
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await RolesService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete role`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const renderTableActionCell = (row: RoleDto) => {
        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem onClick={() => onSelectRole(row, true)}>
                            {t('common.details')}
                        </DropdownItem>
                        {!row.readOnly && hasRolesWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<RoleDto>[] =
        useMemo(() => {
            const cols: ListingTableColumn<RoleDto>[] = [
                {
                    field: 'name',
                    name: t('roles.list.name'),
                    onSearch: onTableFilter,
                    searchField: 'anrc.name'
                },
                {
                    name: t('common.options'),
                    width: '100px',
                    cellAlignment: 'center',
                    preventClick: true,
                    renderCell: renderTableActionCell,
                    cellStyle: { overflow: 'unset' }
                },
            ];
            return cols;
        }, []);

    const getData = async () => {
        try {
            Loading.show();

            const page = await RolesService.getList(criteria);

            page.items.forEach(role => {
                if (role.readOnly || role.system) {
                    role.name = t(('common.roles.' + role.name) as any);
                }
            });

            setRolesPage(page);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get roles list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    useEffect(() => {
        getData();
    }, [criteria]);

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    return (
        <div className='list'>
            <div className={styles.tabHeader}>
                <div>
                    <InputSearch onChangeValue={debounced} placeholder={t('common.search')} ></InputSearch>
                </div>
                <div>
                    {hasRolesWritePolicy && <Button
                        preset='success'
                        text={t('common.new')}
                        onClick={() => onSelectRole(null, false)}
                    ></Button>}
                </div>
            </div>
            <ListingTable
                columns={tableColumns}
                rows={rolesPage?.items || []}
                onRowClick={row => onSelectRole(row, true)}
                allowHover={true}
                initialSearch={{ colField: 'anrc.name', isOrderAsc: true }}
            />
            <PaginationWithInfo
                itemName={t('roles.list.roles')}
                currentPage={rolesPage?.currentPage || 1}
                pageItems={rolesPage?.items.length || 0}
                totalItems={rolesPage?.totalItems || 0}
                itemsPerPage={DEFAULT_PAGINATION_ITEMS_PER_PAGE}
                onChange={page => setCriteria({ ...criteria, page })}
            />

            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.name ?? '' })}></QuestionYesNo>
        </div>
    );
};

export default RolesList;
