import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import { LOGGER_LOG_TYPE, Types } from 'Config';
import { Col, Row } from 'react-flexbox-grid';
import Label from 'common/components/label/Label';
import styles from './ProjectScreen.module.scss';
import { useFieldArray, useForm } from 'react-hook-form';
import InputError from 'common/components/inputError/InputError';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Input from 'common/components/input/Input';
import Button from 'common/components/button/Button';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { ProjectDto, ServerProjectDto } from 'api/projects/models/ProjectDto';
import ProjectsService from 'api/projects/ProjectsService';
import ServersService from 'api/servers/ServersService';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import Select from 'common/components/select/Select';
import IconTrash from 'assets/svg/trash.svg';
import { Periodicity } from 'api/projects/models/Periodicity';
import SelectController from 'common/components/select/SelectController';
import InputController from 'common/components/input/InputController';
import DateTimePickerController from 'common/components/dateTimePicker/DateTimePickerController';
import InputGroup from 'common/components/inputGroup/InputGroup';
import { FaCalendarAlt } from 'react-icons/fa';
import moment from 'moment';
import ColorPicker from 'common/components/colorPicker/ColorPicker';

type Props = {
};

const ProjectScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const { projectId, type } = useParams<{ projectId: string, type: Types }>();
    const isNew = type === 'new';
    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVerificationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_PROJECTS_WRITE']);
    const hasReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_PROJECTS_READ']);
    
    const form = useForm<ProjectDto>();
    const { control, setValue, register, handleSubmit, formState: { errors }, reset, watch } = form;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'serverProject',
        keyName: 'formId'
    });

    const [project, setProject] = useState<ProjectDto | null>(null);
    const [serverList, setServerList] = useState<SelectValueLabel[] | null>(null);
    const [selectedServer, setSelectedServer] = useState<SelectValueLabel | null>(null);
    const [periodicityList, setPeriodicityList] = useState<SelectValueLabel[] | null>(null);

    const [showRemoveServerModal, setShowRemoveServerModal] = useState<boolean>(false);
    const [selectedConnection, setSelectedConnection] = useState<ServerProjectDto | null>(null);

    const [palleteColor, setPalleteColor] = useState(false);

    const getData = async () => {
        try {
            if (!projectId) return;
            Loading.show();

            const result = await ProjectsService.get(projectId);
            setProject(result);
            reset({
                id: result.id,
                name: result.name,
                createdDate: result.createdDate
            });

            if (!!result.serverProject) {
                append(result.serverProject)
            }

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const getServerCatalog = async () => {
        try {
            Loading.show();
            const result = await ServersService.catalog();
            setServerList(result);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const onSubmit = async (formData: ProjectDto) => {
        try {
            Loading.show();
            if (isNew) {
                const id = await ProjectsService.create(formData);
                history.push(`/projects/details/${id}`);
            } else {
                await ProjectsService.update(formData);
                history.push(`/projects/details/${projectId}`);
            }

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update servers`, error);
            Loading.hide();
        }
    };

    const getPeriodicity = () => {
        const arr: SelectValueLabel[] = [];
        for (const item in Periodicity) {
            if (isNaN(Number(item))) {
                arr.push({ value: item, label: t(`projects.details.periodicity.${item}` as any) })
            }
        }
        setPeriodicityList(arr);
    }

    useEffect(() => {
        getServerCatalog();
        getPeriodicity();
    }, []);

    useEffect(() => {
        if(!hasReadPolicy)
        {
            history.push('/access-denied');
        }
        reset(undefined);
        remove();
        getData();
    }, [type, projectId]);

    const onCancel = () => {
        history.push(isNew ? '/projects' : `/projects/details/${projectId}`);
    }

    const onCancelRemoveServer = () => {
        setShowRemoveServerModal(false);
    }

    const onRemoveServer = async () => {
        if (!selectedConnection) return;
        const index = fields.findIndex(x => x.formId === (selectedConnection as any).formId);
        remove(index);
        setSelectedConnection(null);
        setShowRemoveServerModal(false);
    }

    const addServerToForm = () => {
        if (!selectedServer) return;
        const model: ServerProjectDto = {
            serverId: selectedServer.value,
            serverName: selectedServer.label,
            fileRegex: undefined,
        };
        append(model);
        setSelectedServer(null);
    }

    const onChangeColor = (color: string) => {
        form.setValue('color', color);
    }

    const canCreate = (): JSX.Element => {
        return <Box>
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className={styles.container}>
                    <Row className={styles.rowSpace}>
                        <Col xs={12}>
                            <Label className={styles.label}>{t('servers.details.name')} *</Label>
                            <Input
                                placeholder={t('servers.details.name')}
                                defaultValue={project?.name}
                                {...register('name', { required: true })}
                                disabled={false}
                            />
                            <InputError error={errors?.name} />
                        </Col>
                        <Col xs={6}>
                            <Label className={styles.label}>{t('projects.details.color')} *</Label>
                            <Input
                                placeholder={t('projects.details.color')}
                                defaultValue={project?.color}
                                {...register('color', { required: true, maxLength: 250 })}
                                onClick={() => setPalleteColor(true)}
                                style={{ backgroundColor: form.watch('color'), color: form.watch('color'), cursor: 'pointer' }}
                                autoComplete={'off'}
                            />

                            {palleteColor && <ColorPicker
                                value={form.watch('color') ?? ''}
                                onOpenPallete={setPalleteColor}
                                onChangeColor={color => onChangeColor(color)}
                            />}
                            <InputError error={errors?.name} />
                        </Col>
                    </Row>
                    <Row className={styles.rowSpace}>
                        <Col xs={12}>
                            <Label className={styles.label}>{t('projects.details.serverList')} *</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                options={serverList ?? []}
                                value={selectedServer}
                                onChange={(newValue: unknown) => {
                                    setSelectedServer(newValue as SelectValueLabel);
                                }}
                                isDisabled={false}
                            />
                        </Col>
                        <Col xs={6}>
                            <Button
                                type='button'
                                text={t('common.add')}
                                onClick={() => addServerToForm()}
                            />
                        </Col>

                        {!!fields && watch('serverProject') && fields.length > 0 && fields.map((connection, index: number) => {
                            return <Col key={connection.formId} xs={12} md={6}>
                                <div key={index} className={styles.fields}>
                                    <div>
                                        <span>{t('projects.details.serverName')}:</span>
                                        <span style={{ marginLeft: '0.5rem' }}>{connection.serverName}</span>
                                    </div>
                                    <div className={styles.marginTop}>
                                        <Label className={styles.label}>{t('projects.details.regexExpression')}</Label>
                                        <Input
                                            placeholder={t('projects.details.regexExpression')}
                                            defaultValue={connection?.fileRegex}
                                            {...register(`serverProject.${index}.fileRegex`)}
                                            disabled={false}
                                        />
                                        <InputError error={!!errors?.serverProject ? errors?.serverProject[index]?.fileRegex : undefined} />
                                    </div>
                                    <div className={styles.marginTop}>
                                        <Label className={styles.label}>{t('projects.details.periodicity.title')}</Label>
                                        <SelectController
                                            form={form as any}
                                            name={`serverProject.${index}.periodicity`}
                                            options={periodicityList ?? []}
                                            isDisabled={false}
                                        />
                                        <InputError error={!!errors?.serverProject ? errors?.serverProject[index]?.periodicity : undefined} />
                                    </div>
                                    <div className={styles.marginTop}>
                                        <Label className={styles.label}>{t('projects.details.retainer')}</Label>
                                        <InputController
                                            form={form as any}
                                            placeholder={t('projects.details.retainer')}
                                            name={`serverProject.${index}.retainer`}
                                            disabled={false}
                                            type={'number'}
                                        />
                                        <InputError error={!!errors?.serverProject ? errors?.serverProject[index]?.fileRegex : undefined} />
                                    </div>
                                    <div className={styles.marginTop}>
                                        <Label className={styles.label}>{t('projects.details.min_size')}</Label>
                                        <InputController
                                            form={form as any}
                                            placeholder={t('projects.details.min_size')}
                                            name={`serverProject.${index}.minSize`}
                                            disabled={false}
                                            type={'number'}
                                        />
                                        <InputError error={!!errors?.serverProject ? errors?.serverProject[index]?.minSize : undefined} />
                                    </div>
                                    <div className={styles.marginTop}>
                                        <Label className={styles.label}>{t('projects.details.start_date')}</Label>
                                        <DateTimePickerController
                                            form={form}
                                            placeholderText={t('projects.details.start_date')}
                                            onChange={(startDate: Date) => {
                                                setValue(`serverProject.${index}.startDate`, startDate);
                                            }}
                                            selected={project != undefined && project.serverProject != undefined && project.serverProject[index] != undefined && project.serverProject[index].startDate ? moment(project.serverProject[index].startDate).toDate() : null}
                                            customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                            name={`serverProject.${index}.startDate`}
                                            autoComplete='off'
                                            disabled={false} />

                                        <InputError error={!!errors?.serverProject ? errors?.serverProject[index]?.startDate : undefined} />
                                    </div>
                                    <div
                                        className={styles.removeButton}
                                        onClick={() => {
                                            setShowRemoveServerModal(true);
                                            setSelectedConnection(connection);
                                        }}>
                                        <img
                                            src={IconTrash}
                                            className={styles.icon}
                                            alt={'remove logo'} />
                                    </div>
                                </div>
                            </Col>
                        })}
                    </Row>
                </div>
                <QuestionYesNo
                    onNo={onCancelRemoveServer}
                    onYes={onRemoveServer}
                    isVisible={showRemoveServerModal}
                    message={t('common.messages.remove_record')} />
                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onCancel()}
                    />
                    <Button
                        type='submit'
                        text={t('common.save')}
                    />
                </div>
            </form>
        </Box >;
    }

    const cannotCreate = (): JSX.Element => {
        return <Box>
            <div className={styles.permissionContainer}>
                <div>{t('common.no_permission')}</div>
                <Button
                    preset={'secondary'}
                    type='button'
                    text={t('common.cancel')}
                    onClick={() => onCancel()}
                />
            </div>
        </Box>;
    }

    return (
        <ScreenTitle title={t('projects.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('projects.title')} />
                {hasVerificationsWritePolicy ? canCreate() : cannotCreate()}
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default ProjectScreen;
