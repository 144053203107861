/* eslint-disable unused-imports/no-unused-vars */
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../common/services/Loading';
import AlertsService from 'api/alerts/AlertsService';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from 'Config';
import { AlertSearchCriteria } from 'api/alerts/models/AlertSearchCriteria';
import { AlertDto } from 'api/alerts/models/AlertDto';
import { Paged } from 'api/common/types/Page';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import UsersService from 'api/users/UsersService';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { Row } from 'react-flexbox-grid';
import Card from 'common/components/card/Card';
import { AlertInfoDto } from 'api/servers/models/ServerDto';
import { useHistory } from 'react-router-dom';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce/lib';
import Button from 'common/components/button/Button';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { updateTotals } from 'store/alerts/action';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import styles from './AlertsListScreen.module.scss';

type Props = {
};

const AlertsListScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    
    const { addToast } = useToasts();
    const [alertPage, setAlertPage] = useState<Paged<AlertDto>>();
    const [criteria, setCriteria] = useState<AlertSearchCriteria>({
        page: 1,
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        orderColumn: 'date',
        orderBy: 'desc'
    });
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAlertsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ALERTS_WRITE']);

    const getData = async () => {
        try {
            Loading.show();
            const alerts = await AlertsService.getList(criteria);
            setAlertPage(alerts);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    useEffect(() => {
        getData();
    }, [criteria]);

    const navigateToAlert = (alert: AlertInfoDto) => {
        if (alert.origin == 'SERVER_PROJECT') {
            history.push(`/projects/details/${alert.projectId}`);
        } else if (alert.origin == 'SERVER') {
            history.push(`/servers/details/${alert.serverId}`);
        } else if (alert.origin == 'SERVER_FOLDER') {
            history.push(`/folders/details/${alert.folderId}`);
        }
    }

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    const [showRead, setShowRead] = useState<boolean>(true);

    const toogleReadOption = () => {
        setCriteria({ ...criteria, toRead: showRead, page: 1 });
        setShowRead(!showRead);
    }

    const readAllAlerts = async () => {
        try {
            Loading.show();
            if (hasAlertsWritePolicy) {
                await AlertsService.readAll();
                dispatch(updateTotals());
                setCriteria({ ...criteria, toRead: false, page: 1 });
                setShowRead(!showRead);
                Loading.hide();
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALERTS, `Couldn't read all alerts`, error);
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    }

    return (
        <ScreenTitle title={t('alerts.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('alerts.title')} >
                    <InputSearch onChangeValue={debounced} placeholder={t('servers.list.placeholders.search')} />
                    <Button preset={'secondary'} onClick={toogleReadOption}>
                        <div>
                            {showRead ? <FaRegEye size={20} /> : <FaRegEyeSlash size={20} />}
                            <span style={{ marginLeft: '0.5rem' }}>{t('alerts.read')}</span>
                        </div>
                    </Button>
                    {hasAlertsWritePolicy && <Button text={t('alerts.read_all')} onClick={readAllAlerts} />}

                </ScreenHeader>
                <Box>
                    <Row>
                        {alertPage?.items.map((x: AlertDto, index: number) => <Card key={index} alert={{ ...x, isRead: !!x.readDate } as any} index={index} action={() => navigateToAlert(x as any)} redirectAction />)}
                        {!(alertPage?.items ?? []).length && <div className={styles.noItem}>{t('alerts.no_item')}</div>}
                    </Row>
                    <PaginationWithInfo
                        itemName={t('alerts.title')}
                        currentPage={alertPage?.currentPage || 1}
                        pageItems={alertPage?.items.length || 0}
                        totalItems={alertPage?.totalItems || 0}
                        itemsPerPage={DEFAULT_PAGINATION_ITEMS_PER_PAGE}
                        onChange={page => setCriteria({ ...criteria, page })}
                    />
                </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default AlertsListScreen;
