import React from 'react';
import Input, { Props as InputProps } from './Input';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';

type Props = {
    form: UseFormReturn<any>;
    name: string;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
} & InputProps;

const InputController = ({ form, name, rules, ...props }: Props) => {
    return (
        <Controller
            render={({ field }) => {
                return (
                    <>
                        <Input
                            {...field}
                            value={!!field.value || field.value == 0 ? field.value : ''}
                        />
                    </>
                );
            }}
            control={form.control}
            name={name}
            defaultValue={!!form.getValues(name) || form.getValues(name) == 0 ? form.getValues(name) : ''}
            rules={rules}
        />
    );
};

export default InputController;
