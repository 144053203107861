import CustomFile from 'common/models/CustomFile';
import { blobToBase64, isTablet, MIMETYPES_IMAGE } from 'Config';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MediaSelector.module.scss';
import { FaCamera } from 'react-icons/fa';
import colors from 'styles/export/colors.module.scss';
import TrashIcon from 'assets/svg/trash.svg';
import QuestionYesNo from '../questionYesNo/QuestionYesNo';
import Utils from 'common/services/Utils';
import ImageEditor from '../imageEditor/ImageEditor';

type Props = {
    container?: string;
    onAddMedias?: (medias: CustomFile[]) => void;
    onRemoveMedia?: (mediaId: string) => void;
    onUpdateMedia?: (media: CustomFile) => void;
    isMulti?: boolean;
    initialMedias?: CustomFile[];
    isDetails?: boolean;
    canEdit?: boolean;
};

const MediaSelector: React.FC<Props> = ({ container, onAddMedias, onRemoveMedia, onUpdateMedia, isMulti = false, initialMedias = [], isDetails = false, canEdit = false }: Props) => {
    const { t } = useTranslation();

    const key = Utils.makeid(5);

    const isMobile = isTablet(window.innerWidth);

    const [medias, setMedias] = useState<CustomFile[]>([]);
    const [selectedMedia, setSelectedMedia] = useState<CustomFile | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const importFile = () => {
        const inputMedias = document.getElementById(`myImage_${key}`);
        if (inputMedias) {
            inputMedias.click();
        }
    }

    const getData = async () => {
        if (initialMedias.length > 0) {
            initialMedias.map(media => media.urlBase64 = media.url);
            setSelectedMedia(initialMedias[0]);
            setMedias(initialMedias);
        }
    }

    const uploadFile = async (event: any) => {
        const m: CustomFile[] = [];
        for (let size = 0; size < event.target.files.length; size++) {
            const file: CustomFile = event.target.files[size];
            const fileUrl = await blobToBase64(file);
            file.urlBase64 = fileUrl;
            file.id = Utils.newGuid();
            if (container) {
                file.containerName = container;
            }
            m.push(file);
            setSelectedMedia(file);
        }

        if (onAddMedias) {
            onAddMedias([...medias, ...m]);
        }
        setMedias([...medias, ...m]);
    }

    const renderAddMedia = (): JSX.Element => {
        return <div className={styles.addMedia} onClick={importFile}>
            <FaCamera size={isMobile ? 15 : 30} style={{ fill: colors.alto }} />
        </div>
    }

    const renderEmptyMedia = (): JSX.Element => {
        return <div> {t('common.without_image')} </div>
    }

    const renderSingleMedia = (): JSX.Element => {
        return <>
            <div className={styles.mediasList}>
                {selectedMedia && <div className={styles.imagePreviewMini} style={{ backgroundImage: `url('${selectedMedia?.urlBase64}')`, margin: 'unset', cursor: isDetails ? 'default' : '' }}></div>}
                {!selectedMedia && isDetails && renderEmptyMedia()}
                {!selectedMedia && !isDetails && renderAddMedia()}
            </div>
        </>
    }

    const renderMultipleMedia = (): JSX.Element => {
        return <div className={styles.mediasList}>
            {medias.map((media, index) => {
                return <div key={`media-${index}`} className={styles.imagePreviewMini} style={{ backgroundImage: `url('${media.urlBase64}')`, margin: index === 0 && isDetails ? 'unset' : '' }} onClick={() => { setSelectedMedia(media) }}></div>
            })}
            {!isDetails && renderAddMedia()}
        </div>
    }

    const removeMedia = () => {
        setShowRemoveModal(true);
    }

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    }

    const onRemove = () => {
        setShowRemoveModal(false);
        const filteredMedias = [...medias.filter(x => x.id != selectedMedia?.id)];
        setMedias(filteredMedias);
        if (onRemoveMedia) {
            onRemoveMedia(selectedMedia?.id ?? '');
        }
        setSelectedMedia(filteredMedias.length > 0 ? filteredMedias[0] : null);
    }

    const onDrawMedia = (f: CustomFile) => {
        const mediaArr: CustomFile[] = [];
        medias.forEach(media => {
            mediaArr.push(media.id === f.id ? f : media);
        });
        setMedias(mediaArr);
        if (onUpdateMedia) {
            onUpdateMedia(f);
        }
    }

    useEffect(() => {
        getData();
    }, [initialMedias]);

    return (
        <div className={styles.container}>
            <div className={styles.scrollable}>
                {isMulti && renderMultipleMedia()}
                {!isMulti && !selectedMedia && renderSingleMedia()}
            </div>
            <div className={styles.imagePreviewContainer}>
                {selectedMedia && <>
                    {!isDetails && <img className={styles.removeItem} src={TrashIcon} style={{ width: '30px', height: '30px' }} onClick={removeMedia} />}
                    <div className={styles.editorContainer}>
                        <ImageEditor key={`media-${selectedMedia.id}`} height={450} width={450} image={selectedMedia} saveFile={onDrawMedia} isDetails={isDetails} canEdit={canEdit} />
                    </div>
                </>}
            </div>
            <input type="file" id={`myImage_${key}`} hidden multiple={isMulti} name={`myImage_${key}`} accept={MIMETYPES_IMAGE} className="multiple-upload" onChange={uploadFile} />
            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={onRemove}
                isVisible={showRemoveModal}
                message={t('common.messages.remove_media')}>
            </QuestionYesNo>
        </div>
    );
}

export default MediaSelector;
