import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LoginScreen from './screens/login/LoginScreen';
import AuthenticatedLayout from './common/layouts//authenticatedLayout/AuthenticatedLayout';
import ProtectedRoute from './common/routes/ProtectedRoute';
import AccountScreen from './screens/account/AccountScreen';
import NotFoundScreen from './screens/errors/NotFoundScreen';
import PolicyRoute from 'common/routes/PolicyRoute';
import ForbiddenScreen from 'screens/errors/ForbiddenScreen';
import ServersListScreen from 'screens/servers/ServersListScreen';
import ServersScreen from 'screens/servers/ServersScreen';
import ProjectsListScreen from 'screens/projects/ProjectsListScreen';
import ProjectScreen from 'screens/projects/ProjectScreen';
import AlertsListScreen from 'screens/alerts/AlertsListScreen';
import ProjectDetailsScreen from 'screens/projects/ProjectDetailsScreen';
import FoldersListScreen from 'screens/folders/FoldersListScreen';
import FolderScreen from 'screens/folders/FolderScreen';
import SecurityScreen from 'screens/security/SecurityScreen';
import AccessDeniedScreen from 'screens/errors/AccessDeniedScreen';
import HomeScreen from 'screens/home/HomeScreen';
import EndpointsListScreen from 'screens/endpoints/EndpointsListScreen';
import EndpointScreen from 'screens/endpoints/EndpointScreen';



const DefaultRouter = () => {
    return (
        <Router>
            <Switch>
                <Route path="/404" component={NotFoundScreen} />
                <Route path="/403" component={ForbiddenScreen} />
                <Route path="/login" component={LoginScreen} />
                <ProtectedRoute path="*">
                    <AuthenticatedLayout>
                        <Switch>
                            <Route path="/" exact component={HomeScreen} />
                            <Route path="/account/:type" exact component={AccountScreen} />
                            <Route path="/access-denied" exact component={AccessDeniedScreen} />

                            {/* Server */}
                            <PolicyRoute path="/servers" exact component={ServersListScreen} type='OR' policies={['SETTINGUP_SERVERS_READ', 'SETTINGUP_SERVERS_WRITE']} />
                            <PolicyRoute path="/servers/:type/:serverId?/:tabId?/:itemType?/:itemId?" exact component={ServersScreen} type='OR' policies={['SETTINGUP_SERVERS_READ', 'SETTINGUP_SERVERS_WRITE']} />

                            {/* Projects */}
                            <PolicyRoute path="/projects" exact component={ProjectsListScreen} type='OR' policies={['SETTINGUP_PROJECTS_READ', 'SETTINGUP_PROJECTS_WRITE']} />
                            <PolicyRoute path="/projects/details/:projectId?/" exact component={ProjectDetailsScreen} type='OR' policies={['SETTINGUP_PROJECTS_READ', 'SETTINGUP_PROJECTS_WRITE']} />
                            <PolicyRoute path="/projects/:type/:projectId?/" exact component={ProjectScreen} type='OR' policies={['SETTINGUP_PROJECTS_READ', 'SETTINGUP_PROJECTS_WRITE']} />

                            {/* Alerts */}
                            <PolicyRoute path="/alerts" exact component={AlertsListScreen} type='OR' policies={['SETTINGUP_ALERTS_READ', 'SETTINGUP_ALERTS_WRITE']} />

                            {/* Folders */}
                            <PolicyRoute path="/folders" exact component={FoldersListScreen} type='OR' policies={['SETTINGUP_FOLDERS_READ', 'SETTINGUP_FOLDERS_WRITE']} />
                            <PolicyRoute path="/folders/:type/:folderId?/" exact component={FolderScreen} type='OR' policies={['SETTINGUP_FOLDERS_READ', 'SETTINGUP_FOLDERS_WRITE']} />

                            {/* Configurações */}
                            <PolicyRoute path="/security/:tabId?/:type?/:id?" exact component={SecurityScreen} type='OR' policies={[
                                'SETTINGUP_USERS_READ', 'SETTINGUP_USERS_WRITE',
                                'SETTINGUP_ROLES_READ', 'SETTINGUP_ROLES_WRITE',
                                'SETTINGUP_RULES_READ', 'SETTINGUP_RULES_WRITE'
                            ]} />
                            <PolicyRoute path="/endpoints" exact component={EndpointsListScreen} type='OR' policies={['SETTINGUP_ENDPOINTS_READ', 'SETTINGUP_ENDPOINTS_WRITE']} />
                            <PolicyRoute path="/endpoints/:type/:endpointId?/" exact component={EndpointScreen} type='OR' policies={['SETTINGUP_ENDPOINTS_READ', 'SETTINGUP_ENDPOINTS_WRITE']} />
                            <Route>
                                <Redirect to="/404" />
                            </Route>
                        </Switch>
                    </AuthenticatedLayout>
                </ProtectedRoute>
                <Route path="*">
                    <Redirect to="/404" />
                </Route>
            </Switch>
        </Router>
    );
}

export default DefaultRouter;