import { useTranslation } from 'react-i18next';
import styles from './FolderCard.module.scss';
import { FolderDto, ServerFolderListDto } from 'api/folders/models/FolderDto';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { DATE_TIME_FORMAT_DEFAUT } from 'Config';
import ReactTooltip from 'react-tooltip';

type Props = {
    folder: FolderDto;
};

const FolderCard: React.FC<Props> = ({ folder }: Props) => {
    const { t } = useTranslation();
  
    return (
        <>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{folder.name}</div>
            </div>            
            <div>
                <div className={styles.serverListContainer}>
                    <div className={styles.serverListTitle}>{t('folders.list.serverList')}:</div>
                    <div className={styles.serverCell}>
                        {(folder.serverFolder ?? []).map((x: ServerFolderListDto, i: number) =>
                            <span key={`index-${i}`} className={styles.serverRepetitionContainer}>
                                {x.serverName} : <strong><DateFormat className={styles.date} value={x.lastSync ?? ''} format={DATE_TIME_FORMAT_DEFAUT} /></strong>
                            </span>)}
                    </div>
                </div>
            </div>            
            <div className={styles.fill} />
            <div className={styles.dateContainer}>
                <span>{t('projects.list.createdDate')}</span>
                <DateFormat className={styles.date} value={folder.createdDate ?? ''} />
            </div>
            {folder.hasAlerts && <div className={styles.alertContainer} >
                <a data-tip data-for={`alerts-${folder.id}`}>
                    <span className={styles.floatingBadge}>
                        {(folder.alertCount ?? 0) > 99 ? <small>99+</small> : folder.alertCount}
                    </span>
                </a>
            </div>}
            <ReactTooltip id={`alerts-${folder.id}`}>
                <span>{t('folders.list.tooltips.alerts')}</span>
            </ReactTooltip>
        </>
    );
};

export default FolderCard;
