import { useTranslation } from 'react-i18next';
import styles from './ServerCard.module.scss';
import { ServerDto } from 'api/servers/models/ServerDto';
import DateFormat from 'common/components/dateFormat/dateFormat';
import colors from 'styles/export/colors.module.scss';
import ReactTooltip from 'react-tooltip';

type Props = {
    server: ServerDto;
};

const ServerCard: React.FC<Props> = ({ server }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{server.name}</div>
            </div>
            <div className={styles.statusContainer}>
                <span>{t('servers.list.status')}</span>
                <a data-tip data-for={`status-${server.id}`}>
                    <div className={styles.status} style={{ backgroundColor: server.isAlive ? colors.success : colors.danger }} />
                </a>
            </div>
            <div className={styles.availableSpaceContainer}>
                <span>{t('servers.list.available_space')}</span>
                <span className={styles.availableSpace}>{server.freeDiskSpaceFormatted}</span>
            </div>
            <div className={styles.fill} />
            <div className={styles.dateContainer}>
                <span>{t('servers.list.createdDate')}</span>
                <DateFormat className={styles.date} value={server.createdDate ?? ''} />
            </div>
            {server.hasAlerts && <div className={styles.alertContainer} >
                <a data-tip data-for={`alerts-${server.id}`}>
                    <span className={styles.floatingBadge}>
                        {(server.alertCount ?? 0) > 99 ? <small>99+</small> : server.alertCount}
                    </span>
                </a>
            </div>}
            <ReactTooltip id={`status-${server.id}`}>
                <span>{t(`servers.list.tooltips.status.${server.isAlive}` as any)}</span>
            </ReactTooltip>
            <ReactTooltip id={`alerts-${server.id}`}>
                <span>{t('servers.list.tooltips.alerts')}</span>
            </ReactTooltip>
        </>
    );
};

export default ServerCard;
