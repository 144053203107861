import { removeAccents } from 'Config';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

class Utils {
    public newGuid(): string {
        return uuidv4();
    }

    public objectArraybyStringPath(o: any, s: string) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        const a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            const k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    public getMonthsInRange(startDate: Date, endDate: Date) {
        let dStartDate = dayjs(startDate);
        const dEndDate = dayjs(endDate);
        const result = [];

        while (dStartDate.isBefore(dEndDate) || dStartDate.isSame(dEndDate, 'month')) {
            result.push(dStartDate.toDate());
            dStartDate = dStartDate.add(1, 'month');
        }

        return result;
    }

    public getYearsInRange(startDate: Date, endDate: Date) {
        let dStartDate = dayjs(startDate);
        const dEndDate = dayjs(endDate);
        const result = [];

        while (dStartDate.isBefore(dEndDate) || dStartDate.isSame(dEndDate, 'year')) {
            result.push(dStartDate.toDate());
            dStartDate = dStartDate.add(1, 'year');
        }

        return result;
    }

    public getMonthsInYear(date: Date) {
        return new Array(12).fill({}).map((_, i) => (new Date(date.getFullYear(), i, 1, 0, 0, 0, 0)));
    }

    public sortAlphabetically(arr: any[]) {
        return arr.sort(function (a, b) {
            if (removeAccents(a.label) < removeAccents(b.label)) { return -1; }
            if (removeAccents(a.label) > removeAccents(b.label)) { return 1; }
            return 0;
        });
    }

    public makeid(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    public isStringNullOrEmpty(str: string): boolean {
        return !str ? true : !str.trim();
    }

    private formatDatePartValue(value?: number): string {
        return `${value ? (value < 10 ? ('0' + value) : value) : '00'}`;
    }

    public generateDate(year: number, month: number, day: number, hour?: number, minutes?: number, seconds?: number): Date {
        const monthAux = this.formatDatePartValue((month + 1));
        const dayAux = this.formatDatePartValue(day);
        const hourAux = this.formatDatePartValue(hour);
        const minutesAux = this.formatDatePartValue(minutes);
        const secondsAux = this.formatDatePartValue(seconds);
        const aux = `${year}-${monthAux}-${dayAux}T${hourAux}:${minutesAux}:${secondsAux}.000Z`;
        return new Date(aux);
    }

    public getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }
}

export default new Utils();