import Http from '../../common/services/Http';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { Paged } from 'api/common/types/Page';
import { ProjectSearchCriteria } from './models/ProjectSearchCriteria';
import { ProjectDto, ProjectListDto } from './models/ProjectDto';
import { ProjectViewModel } from './models/ProjectViewModel';

class ProjectsService {
    public getList(criteria: ProjectSearchCriteria) {
        return Http.get<Paged<ProjectListDto>>('projects', criteria);
    }

    public get(id: string) {
        return Http.get<ProjectDto>(`projects/${id}`);
    }

    public catalog() {
        return Http.get<SelectValueLabel[]>('projects/catalog');
    }

    public create(model: ProjectDto) {
        const formatedModel: ProjectViewModel = {
            id: model.id,
            name: model.name,
            color: model.color,
            serverList: (model.serverProject ?? []).map(x => ({
                id: x.id,
                serverId: x.serverId,
                fileRegex: x.fileRegex,
                periodicity: x.periodicity,
                retainer: x.retainer,
                startDate: x.startDate,
                minSize: x.minSize
            }))
        };

        return Http.post<string>('projects', formatedModel);
    }

    public update(model: ProjectDto) {
        const formatedModel: ProjectViewModel = {
            id: model.id,
            name: model.name,
            color: model.color,
            serverList: (model.serverProject ?? []).map(x => ({
                id: x.id,
                serverId: x.serverId,
                fileRegex: x.fileRegex,
                periodicity: x.periodicity,
                retainer: x.retainer,
                startDate: x.startDate,
                minSize: x.minSize
            }))
        };

        return Http.put('projects', formatedModel);
    }

    public remove(id: string) {
        return Http.put('projects/deactivate', { id: id });
    }
}

export default new ProjectsService();