import styles from './ScreenHeaderButton.module.scss';

type Props = {
    icon: string;
    className?: string;
    onClick: () => void;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const ScreenHeaderButton = ({ children, icon, className, onClick }: Props) => (
    <div className={`${styles.button} ${className || ''}`} onClick={onClick}>
        <img src={icon} />
        {children}
    </div>
);

export default ScreenHeaderButton;
